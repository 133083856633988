import React, { Component } from 'react'
import { animateScroll } from 'react-scroll'
import ReactGA from 'react-ga'

import Header from 'Layout/Header'
import SubHeader from 'Layout/SubHeader'

import Info from 'Scenes/Info'
import Services from 'Scenes/Services'
import Contact from 'Scenes/Contact'
import Gallery from 'Scenes/Gallery'
import Footer from 'Layout/Footer'

const { NODE_ENV } = process.env
if (NODE_ENV === 'production') {
  ReactGA.initialize('G-DYWFZX91VR')
}

class Welcome extends Component {
  state = {
    activeNav: 'home',
  }

  links = {
    home: {
      name: 'home',
      rel: 'infoEl',
    },
    service: {
      name: 'usluge',
      rel: 'servicesEl',
    },
    gallery: {
      name: 'galerija',
      rel: 'galleryEl',
    },
    contact: {
      name: 'kontakt',
      rel: 'contactEl',
    },
  }

  linkNavigation = [
    this.links.home,
    this.links.service,
    this.links.gallery,
    this.links.contact,
  ]

  elementRefs = {
    infoEl: React.createRef(),
    servicesEl: React.createRef(),
    galleryEl: React.createRef(),
    contactEl: React.createRef(),
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      const { activeNav } = this.state
      const visibleElement = this.linkNavigation.find((item) => {
        const elementItem = this.elementRefs[item.rel].current.getBoundingClientRect()
        const isElementVisible = this.ifElementVisible(elementItem)
        return isElementVisible
      })
      const { name = this.links.home.name } = visibleElement || {}
      if (activeNav !== name) {
        this.setState({
          activeNav: name,
        })
      }
    })
  }

  ifElementVisible = (element) => {
    const {
      top,
      height,
    } = element
    const nav = 60
    if (top - nav <= 0) {
      const positiveTop = Math.abs(top)
      return positiveTop + nav <= height
    }
    return false
  }

  handleNavClick = (prop) => {
    const { rel, name } = prop
    const { current = null } = this.elementRefs[rel]
    if (name === 'home') {
      animateScroll.scrollTo(0)
    } else if (current) {
      const { offsetTop } = current
      animateScroll.scrollTo(offsetTop - 60)
    }

    ReactGA.pageview(name)
  }

  render() {
    return (
      <div>
        <SubHeader />
        <Header
          onClick={this.handleNavClick}
          linkNavigation={this.linkNavigation}
          activeNav={this.state.activeNav}
        />
        <Info getEl={this.elementRefs.infoEl} />
        <Services getEl={this.elementRefs.servicesEl} />
        <Gallery getEl={this.elementRefs.galleryEl} />
        <Contact getEl={this.elementRefs.contactEl} />
        <Footer />
      </div>
    )
  }
}

export default Welcome
