import React from 'react'

import {
  StFooter,
} from './Footer.styled'

function Footer() {
  return (
    <StFooter>
      <div className="page-holder">
        <p>
          &#169; 2021 Rent-a Dino
        </p>
        <p>
          Develop by: &nbsp;
          <a
            target="_blank"
            href="https://dexdev.org"
            rel="noreferrer noopener"
          >
            dexdev.org
          </a>
        </p>
      </div>
    </StFooter>
  )
}

export default Footer
