import styled from 'styled-components'

import Variable from 'Constant/variable.styled'

import highway from 'Assets/highway.jpg'

export const InfoBackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.6;

  div {
    background-image: url("${highway}");
    background-position: 0 -100px;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
`

export const InfoWrapper = styled.section`
  background-color: #000;
  text-align: center;
  padding: 50px 0;
  position: relative;
  color: #fff;
  min-height: 550px;

  .page-holder {
    position: relative;
  }

  p {
    max-width: 700px;
    margin: 0 auto 10px;
    text-shadow: ${Variable.textShadow};
    font-size: 18px;
  }

  h1 {
    text-shadow: ${Variable.textShadow};
  }
`
