import PropTypes from 'prop-types'
import React, { Component } from 'react'

import attachRoutes from './routes'

class App extends Component {
  render() {
    const { location } = this.props
    return (
      <div>
        { attachRoutes({ location }) }
      </div>
    )
  }
}

App.propTypes = {
  location: PropTypes.object,
}

export default App
