import styled from 'styled-components'
import Variable from 'Constant/variable.styled'

import { device } from 'Constant/media.styled'

export const StServicesStyled = styled.section`
  box-shadow: ${Variable.boxShadow};
  margin-top: 50px;
  padding: 20px;

  @media ${device.mobileL} {
    padding: 40px;
  }

  img {
    max-width: 100%;
  }
`

export const StServicesItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  margin-top: 35px;
`

export const StServicesItemWrapper = styled.div`
  width: 100%;
  padding-left: 30px;

  @media ${device.mobileL} {
    width: 50%;
  }

  @media ${device.tablet} {
    width: 33.33%;
  }

  @media ${device.tabletXl} {
    width: 25%;
  }
`

export const StServicesItem = styled.div`
  padding: 7px;
  margin-bottom: 20px;
  box-shadow: ${Variable.boxShadow};
`
