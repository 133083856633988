import styled from 'styled-components'
import { slickDefaultStyle } from 'Constant/sslick.styled'

import Variable from 'Constant/variable.styled'

export const StImageWrapper = styled.div`
  padding: 20px;
  background: #f4f4f4;
  border-radius: 4px;
`

export const StSlider = styled(slickDefaultStyle)`
  padding: 20px;
  position: relative;
  background-color: ${Variable.colorFooterSec};

  img {
    max-width: 100%;
  }

  .slick-slide {
    padding: 15px;
  }

  .slick-arrow {
    position: absolute;
    bottom: 0;
    cursor: pointer;
    z-index: 1;

    &.slick-next {
      right: 0;
    }

    &.slick-prev {
      left: 0;
    }
  }

  .slick-dots {
    margin: 0;
    text-align: center;
    padding-bottom: 10px;

    li {
      position: relative;
      display: inline-block;
      margin: 0 5px;
      padding: 0;
      width: 8px;
      height: 8px;
      border-radius: 10px;
      background-color: #E1E1E1;

      &.slick-active {
        background-color: ${Variable.colorSec};
      }

      button {
        font-size: 0;
        line-height: 0;
        display: block;
        padding: 5px;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
        cursor: pointer;
      }
    }
  }
`
