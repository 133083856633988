import React, { Component } from 'react'

import { ImFacebook2 } from 'react-icons/im'
import variable from 'Constant/variable.styled'

import { StSocialLinks } from './SocialLinks.styled'

class SocialLinks extends Component {
  links = [
    {
      name: 'fb',
      Icon: ImFacebook2,
      link: 'https://www.facebook.com/Rent-a-Dino-111622880449587',
    },
  ]

  settings = {
    size: '17px',
    color: variable.colorSec,
  }

  renderLinks = () => this.links.map((link) => {
    const { Icon, link: itemLink, name } = link
    return (
      <a
        key={name}
        target="_blank"
        href={itemLink}
        rel="noreferrer noopener"
      >
        <Icon {...this.settings} />
      </a>
    )
  })

  render() {
    return (
      <StSocialLinks>
        { this.renderLinks() }
      </StSocialLinks>
    )
  }
}

export default SocialLinks
