import styled from 'styled-components'

import Variable from 'Constant/variable.styled'
import { device } from 'Constant/media.styled'

export const StFooter = styled.footer`
  background-color: ${Variable.colorFooterSec};
  padding: 28px 0;
  color: #fff;

  a {
    color: ${Variable.colorSec};
  }

  p {
    @media ${device.mobileL} {
      margin: 0;
    }
  }

  .page-holder {
    text-align: center;

    @media ${device.mobileL} {
      text-align: left;
      display: flex;
      justify-content: space-between;
    }
  }
`
