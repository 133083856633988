import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
  StHeader,
  StHeaderFixed,
  StHeaderLogo,
  StNavigation,
  StNavigationUl,
  StNavigationItem,
  StNavigationLink,
  StyledBurger,
} from './Header.styled'

class Header extends Component {
  state = {
    menuOpened: false,
  }

  handleMenuState = () => {
    this.setState({
      menuOpened: !this.state.menuOpened,
    })
  }

  handleEventClick = (nav) => {
    const { onClick } = this.props
    if (onClick) {
      onClick(nav)
    }
    this.handleMenuState()
  }

  renderNavigation = () => this.props.linkNavigation.map((nav) => (
    <StNavigationItem
      key={nav.name}
      className={this.getActiveClass(nav.name)}
    >
      <StNavigationLink
        onClick={() => this.handleEventClick(nav)}
      >
        {nav.name}
      </StNavigationLink>
    </StNavigationItem>
  ))

  renderButton = () => {
    const { menuOpened } = this.state
    return (
      <StyledBurger
        open={menuOpened}
        onClick={this.handleMenuState}
      >
        <div />
        <div />
        <div />
      </StyledBurger>
    )
  }

  getActiveClass = (name) => {
    const { activeNav } = this.props
    if (activeNav === name) {
      return 'active'
    }
    return null
  }

  getClassName = () => {
    const { menuOpened } = this.state
    if (menuOpened) {
      return 'opened'
    }
    return null
  }

  renderNavWrapper = () => (
    <div className="page-holder">
      <StHeaderLogo href={window.location}>Rent-a Dino</StHeaderLogo>
      { this.renderButton() }
      <StNavigation
        className={this.getClassName()}
      >
        <StNavigationUl>
          { this.renderButton() }
          { this.renderNavigation() }
        </StNavigationUl>
        <div className="line" />
      </StNavigation>
    </div>
  )

  render() {
    return (
      <>
        <StHeader>
          { this.renderNavWrapper() }
        </StHeader>

        <StHeaderFixed>
          { this.renderNavWrapper() }
        </StHeaderFixed>
      </>
    )
  }
}

Header.propTypes = {
  onClick: PropTypes.func,
  linkNavigation: PropTypes.array,
  activeNav: PropTypes.string,
}

export default Header
