import PropTypes from 'prop-types'
import React, { Component } from 'react'

import image1 from 'Assets/slike/image-1.jpeg'
import image2 from 'Assets/slike/image-2.jpeg'
import image3 from 'Assets/slike/image-3.jpeg'
import image4 from 'Assets/slike/image-4.jpeg'
import image5 from 'Assets/slike/image-5.jpeg'

class ImageLoader extends Component {
  imageList = {
    image1,
    image2,
    image3,
    image4,
    image5,
  }

  getImageByKey = (name) => this.imageList[name] || null

  render() {
    const { imageName } = this.props
    if (!imageName) {
      return null
    }
    const image = this.getImageByKey(imageName)
    if (!image) {
      return null
    }
    return (
      <img src={image} />
    )
  }
}

ImageLoader.propTypes = {
  imageName: PropTypes.string,
}

ImageLoader.defaultProps = {
  imageName: null,
}

export default ImageLoader
