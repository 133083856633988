import React from 'react'

import SocialLinks from '@/components/SocialLinks'

import { StSubHeader } from './SubHeader.styled'

function SubHeader() {
  return (
    <StSubHeader>
      <div className="page-holder">
        Pozovite nas: +385 99 191 7030
        <SocialLinks />
      </div>
    </StSubHeader>
  )
}

export default SubHeader
