import PropTypes from 'prop-types'
import React, { Component } from 'react'

import airport from 'Assets/services/airport.jpg'
import business from 'Assets/services/business.jpg'
import cruse from 'Assets/services/cruse.jpg'
import girlNight from 'Assets/services/girl_night.jpg'
import Party from 'Assets/services/Party.jpg'
import picnics from 'Assets/services/picnics.jpg'
import religion from 'Assets/services/religion.jpg'
import renta from 'Assets/services/renta.jpg'
import shop from 'Assets/services/shop.jpg'
import ski from 'Assets/services/ski.jpg'

import {
  StServicesStyled,
  StServicesItems,
  StServicesItem,
  StServicesItemWrapper,
} from './Services.styled'

class Services extends Component {
  serviceList = [
    {
      id: 'outlet',
      name: 'Outleti / shopping',
      image: shop,
    },
    {
      id: 'devojacko',
      name: 'Djevojačke / momačke večeri te sve ostale vrste proslava',
      image: girlNight,
    },
    {
      id: 'hodocasca',
      name: 'Vjerska svetišta, hodočašća',
      image: religion,
    },
    {
      id: 'izleti',
      name: 'Izleti',
      image: picnics,
    },
    {
      id: 'koncerti',
      name: 'Koncerti, sportski događaji',
      image: Party,
    },
    {
      id: 'lukezracne',
      name: 'Zračne luke',
      image: airport,
    },
    {
      id: 'krstarenja',
      name: 'Putničke pomorske luke / krstarenja',
      image: cruse,
    },
    {
      id: 'skijalista',
      name: 'Skijališta / ljetovališta',
      image: ski,
    },
    {
      id: 'putovanja',
      name: 'Privatna / poslovna putovanja',
      image: business,
    },
    {
      id: 'najam',
      name: 'Najam kombi vozila',
      image: renta,
    },
  ]

  render() {
    return (
      <div
        ref={this.props.getEl}
        className="page-holder"
        style={{ marginBottom: '20px' }}
      >
        <StServicesStyled>
          <h2>Nase usluge</h2>
          <p>
            Pružamo profesionalnu uslugu prijevoza putnika kombi (2- 8 putnika) vozilima neupitne kvalitete kao i vozačko znanje i iskustvo naših vozača.
          </p>
          <StServicesItems>
            {
              this.serviceList.map((item) => (
                <StServicesItemWrapper key={item.id}>
                  <StServicesItem>
                    <img src={item.image} />
                    <h3>{ item.name }</h3>
                  </StServicesItem>
                </StServicesItemWrapper>
              ))
            }
          </StServicesItems>
        </StServicesStyled>
      </div>
    )
  }
}

Services.propTypes = {
  getEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
}

export default Services
