import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
  InfoWrapper,
  InfoBackgroundWrapper,
} from './info.styled'

const text = [
  'Vršimo usluge prijevoza prema Vašim željama i upitima, neovisno radi li se o poslovnim, ili privatnim putovanjima.',
  'Nudimo uslugu “od vrata do vrata”, odnosno dolazimo na vašu kućnu adresu i u potpunosti se prilagođavamo potrebama Vašeg putovanja vezano uz vrijeme polaska, dolaska, povratka, kao i uz druge zahtjeve koje biste mogli imati.',
]

class Info extends Component {
  textOutput = text.map((textItem, id) => (
    <p key={id}>{textItem}</p>
  ))

  render() {
    return (
      <InfoWrapper ref={this.props.getEl}>
        <InfoBackgroundWrapper>
          <div />
        </InfoBackgroundWrapper>
        <div className="page-holder">
          <h1 className="main-title">Prijevoz putnika</h1>
          { this.textOutput }
        </div>
      </InfoWrapper>
    )
  }
}

Info.propTypes = {
  getEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
}

export default Info
