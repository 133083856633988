/* eslint-disable no-else-return */
/**
 * @media ${deviceTo.mobileL} {
 *   style...
 * }
 * @type {{tablet: number, desktop: number, laptopL: number, mobileS: number, mobileM: number, laptop: number, mobileL: number}}
 */
export const mediaTypes = {
  mobileS: 'mobileS',
  mobileM: 'mobileM',
  mobileL: 'mobileL',
  tabletM: 'tabletM',
  tablet: 'tablet',
  tabletXl: 'tabletXl',
  laptop: 'laptop',
  laptopM: 'laptopM',
  laptopL: 'laptopL',
  desktop: 'desktop',
}

export const size = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 480,
  tabletM: 600,
  tablet: 768, // mobile scss
  tabletXl: 992,
  laptop: 1024,
  laptopM: 1280,
  laptopL: 1440,
  desktop: 2560,
}

export const device = {
  mobileS: `(min-width: ${size.mobileS}px)`,
  mobileM: `(min-width: ${size.mobileM}px)`,
  mobileL: `(min-width: ${size.mobileL}px)`,
  tabletM: `(min-width: ${size.tabletM}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  tabletXl: `(min-width: ${size.tabletXl}px)`,
  laptop: `(min-width: ${size.laptop}px)`,
  laptopM: `(min-width: ${size.laptopM}px)`,
  laptopL: `(min-width: ${size.laptopL}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  desktopL: `(min-width: ${size.desktop}px)`,
}

export const deviceTo = {
  mobileS: `(max-width: ${size.mobileS - 1}px)`,
  mobileM: `(max-width: ${size.mobileM - 1}px)`,
  mobileL: `(max-width: ${size.mobileL - 1}px)`,
  tabletM: `(max-width: ${size.tabletM - 1}px)`,
  tablet: `(max-width: ${size.tablet - 1}px)`,
  tabletXl: `(max-width: ${size.tabletXl - 1}px)`,
  laptop: `(max-width: ${size.laptop - 1}px)`,
  laptopM: `(max-width: ${size.laptopM - 1}px)`,
  laptopL: `(max-width: ${size.laptopL - 1}px)`,
  desktop: `(max-width: ${size.desktop - 1}px)`,
  desktopL: `(max-width: ${size.desktop - 1}px)`,
}

export const isDevice = (paramSize, paramDeviceTo) => {
  const isDeviceTo = paramDeviceTo || false
  const { innerWidth } = window
  const breakPoint = size[paramSize]
  if (!breakPoint) {
    return false
  } else if (isDeviceTo && innerWidth < breakPoint) {
    return true
  } else if (!isDeviceTo && innerWidth > breakPoint) {
    return true
  }
  return false
}
