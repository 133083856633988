import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Slider from 'react-slick'
import {
  AiFillLeftCircle,
  AiFillRightCircle,
} from 'react-icons/ai'

import variable from 'Constant/variable.styled'

import { size } from 'Constant/media.styled'

import ImageLoader from '@/components/imageLoader'

import {
  StSlider,
  StImageWrapper,
} from './Gallery.styled'

const arrow = (type) => {
  const settings = {
    size: '40px',
    color: variable.colorSec,
  }
  if (type === 'next') {
    return <AiFillLeftCircle {...settings} />
  }
  return <AiFillRightCircle {...settings} />
}

class Gallery extends Component {
  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: arrow('next'),
    nextArrow: arrow('prev'),
    responsive: [
      {
        breakpoint: size.tabletXl,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: size.mobileL,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  images = [
    'image1',
    'image2',
    'image3',
    'image4',
    'image5',
  ]

  renderImage = (image) => (
    <StImageWrapper key={image}>
      <ImageLoader imageName={image} />
    </StImageWrapper>
  )

  renderImages = () => this.images.map((image) => this.renderImage(image))

  render() {
    return (
      <StSlider ref={this.props.getEl}>
        <Slider {...this.settings}>
          { this.renderImages() }
        </Slider>
      </StSlider>
    )
  }
}

Gallery.propTypes = {
  getEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
}

export default Gallery
