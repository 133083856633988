import styled from 'styled-components'

export const StSubHeader = styled.div`
  background-color: #272d3d;
  color: #fff;
  padding: 12px 0;
  position: relative;
  z-index: 2;

  .page-holder {
    display: flex;
    justify-content: space-between;
  }
`
