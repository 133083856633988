import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
  StContact,
  StContactBlock,
} from './Contact.styled'

class Contact extends Component {
  render() {
    return (
      <StContact ref={this.props.getEl}>
        <div className="page-holder">

          <StContactBlock>
            <h2>Kontakt</h2>
            <div>
              <p>
                TEL: +385 991917030
                <span className="supText">
                (poziv, SMS, Viber, WhatsApp)
              </span>
              </p>
              <p>
                MAIL: <a href="mailto:dinosadilek10@gmail.com">dinosadilek10@gmail.com</a>
              </p>

              <h3>Na svaki vaš upit odgovaramo u najkraćem mogućem roku, svakim danom 0/24h</h3>
            </div>
          </StContactBlock>
        </div>
      </StContact>
    )
  }
}

Contact.propTypes = {
  getEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
}

export default Contact
