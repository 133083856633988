/* eslint-disable */
import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'

import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'

import { routerMiddleware, connectRouter, ConnectedRouter } from 'connected-react-router'

import createHistory from 'history/createBrowserHistory'

import appReducer from '@/App/reducer'

import { GlobalStyle } from './global.styled'

import App from './App'

const history = createHistory()

const store = createStore(
  // connectRouter(history)(appReducer),
  appReducer(history),
  applyMiddleware(
    promise(),
    thunk,
    routerMiddleware(history),
  ),
)

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Fragment>
        <GlobalStyle />
        <App />
      </Fragment>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app'),
)

module.hot.accept()
