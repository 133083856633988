import styled from 'styled-components'
import Variable from 'Constant/variable.styled'

import { device, deviceTo } from 'Constant/media.styled'

const StMainHeader = styled.header`
  position: relative;
  z-index: 2;
  background-color: #1f2433;
  padding: 8px 0;

  .page-holder {
    display: flex;
    align-items: center;
  }
`

export const StHeader = styled(StMainHeader)`
  .page-holder {
    @media ${deviceTo.tabletXl} {
      display: block;
    }
  }
`

export const StHeaderFixed = styled(StMainHeader)`
  border-bottom: 1px solid ${Variable.colorSec};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  @media ${deviceTo.tabletM} {
    min-height: 50px;
  }
`

export const StHeaderLogo = styled.a`
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-size: 52px;
  font-weight: 600;
  text-decoration: none;
  text-shadow: rgba(0, 0, 0, 0.7) 0 1px 2px;

  ${StHeaderFixed} & {
    font-size: 16px;
  }
`

export const StNavigation = styled.nav`
  margin-left: 50px;
  position: relative;
  padding-bottom: 12px;
  padding-top: 12px;

  ${StHeaderFixed} & {
    margin-left: auto;
    margin-right: auto;
  }

   ${StHeader} & {
     @media ${deviceTo.tabletXl} {
       margin: 10px 0 0;
       display: flex;
       justify-content: center;
     }
   }

  @media ${deviceTo.tabletM} {
    position: fixed;
    background: #000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 !important;
    display: flex;
    justify-content: center;

    transform: translateX(-100%);
    transition: transform .3s ease;

    &.opened {
      transform: translateX(0);
    }
  }

  .line {
    height: 2px;
    position: absolute;
    bottom: 0;
    margin: 10px 0 0 0;
    background: #16d428;
  }
`

export const StNavigationUl = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;

  @media ${deviceTo.tabletM} {
    display: block;
    margin-top: 55px;
  }
`

export const StNavigationItem = styled.li`
  margin: 0 40px 0 0;
  opacity: .4;
  transition: all 0.4s ease;
  position: relative;

  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 110%;
    border-bottom: 5px solid transparent;
    position: absolute;
    bottom: -19px;
    left: -5%;
  }

  @media ${deviceTo.tabletM} {
    margin-top: 18px;
    margin-bottom: 18px;
  }

  &:hover {
    opacity: .7;
  }

  &.active {
    opacity: 1;

    &:after {
      border-color: ${Variable.colorSec};
    }
  }

  &:last-child {
    margin-right: 0;
  }
`

export const StNavigationLink = styled.a`
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  letter-spacing: .2em;
  font-size: 14px;
  cursor: pointer;
`

export const StyledBurger = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  ${StHeaderFixed} & {
    top: 12px;
  }

  @media ${device.tabletM} {
    display: none;
  }

  &:focus {
    outline: none;
  }

  div {
    width: ${({ open }) => (open ? '2.1rem' : '2rem')};
    height: 0.25rem;
    background: ${({ open }) => (open ? '#fff' : Variable.colorSec)};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 2px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`
