import styled from 'styled-components'
import Variable from 'Constant/variable.styled'
import { device, deviceTo } from 'Constant/media.styled'

export const StContact = styled.section`
  background-color: #ccc;

  .page-holder {
    display: flex;

    @media ${deviceTo.mobileL} {
      padding: 0;
    }
  }
`

export const StContactBlock = styled.article`
  background-color: ${Variable.colorFooter};
  padding: 45px 20px;
  color: #fff;
  width: 100%;
  margin: 0;

  @media ${device.mobileL} {
    margin-right: 18px;
    margin-left: auto;
    max-width: 540px;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
`
